<template>
  <b-card title="">
    <h3 class="text-primary">Users Table</h3>
    <b-row>
      <b-col md="6" class="mb-1"> </b-col>
      <b-col md="5" class="mb-1">
        <b-input-group class="input-group-merge">
          <b-form-input
            placeholder="Search User"
            @change="getUser"
            v-model="name_filter"
            size="sm"
          />
          <b-input-group-append>
            <b-button size="sm" variant="primary"
              ><feather-icon
                icon="SearchIcon"
                class="text-white"
                @click="getUser"
            /></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col md="1" class="mb-1">
        <b-form-group>
          <b-button
            v-if="$store.state.app.user.permissions.includes('user.add_user')"
            class="btn btn-success"
            @click="gotoAdduser()"
            size="sm"
          >
            Add +
          </b-button>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          v-if="userTable.length"
          bordered
          hover
          responsive
          :per-page="perPage"
          :items="userTable"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(#)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(full_name)="data">
            <span class="text-capitalize"> {{ data.item.full_name }}</span>
          </template>
          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
          <template #cell(group)="data">
            <b-badge
              variant="primary"
              v-for="(item, i) in data.item.groups"
              :key="i"
              class="mr-1"
            >
              {{ item.name }}
            </b-badge>
          </template>
          <template #cell(last_login)="data">
            <div v-if="data.item.last_login">
              <span>{{ new Date(data.item.last_login).toLocaleString() }}</span>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button
                v-if="
                  $store.state.app.user.permissions.includes('user.change_user')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="btn-icon rounded-circle"
                @click="gotoEditUser(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="
                  $store.state.app.user.permissions.includes('user.delete_user')
                "
                variant="gradient-danger"
                class="ml-1 btn-icon rounded-circle"
                @click="showModal(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <div v-else>
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-4"
          >
            <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Data Found</h3>
          </div>
        </div>
      </b-col>

      <b-col cols="12">
        <b-row>
          <b-col md="2" sm="4">
            <!-- <b-form-group class="mb-0">
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group> -->
          </b-col>
          <b-col md="10" sm="4">
            <b-pagination-nav
              align="right"
              v-model="currentPage"
              :numberOfPages="total"
              :total-rows="rows"
              :link-gen="linkGen"
              :per-page="perPage"
              use-router
            ></b-pagination-nav>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      id="modal-danger"
      ref="modal-danger"
      ok-only
      ok-variant="danger"
      @ok="deleteUser(userid)"
      modal-class="modal-danger"
      centered
      title="Delete !"
    >
      <b-card-text>
        <!-- {{this.userid}} -->
        Are You Sure to Delete the User ?
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardText,
  BTable,
  BAvatar,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  VBModal,
  BPaginationNav,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BAvatar,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    VBModal,
    BPaginationNav,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 9,
      rows: 0,
      total: 1,
      userTable: [],
      pageOptions: [3, 5, 10],
      totalRows: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        "#",
        {
          key: "full_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "group",
          label: "Group",
        },
        {
          key: "current_ip",
          label: "Current IP",
        },
        {
          key: "last_login",
          label: "Last Login",
        },
        { key: "action", label: "Action", sortable: true },
      ],
      items: [],
      actions: [
        {
          1: "primary",
          2: "danger",
        },
      ],
      userid: "",
      name_filter: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.getUser(false);
    },
  },
  mounted() {
    this.getUser();
  },
  created() {
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    showModal(itemid) {
      this.$refs["modal-danger"].show();
      this.userid = itemid;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    gotoAdduser() {
      this.$router.push("/eca-createuser");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getUser: function (reset = true) {
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL + `user/users/?page=` + this.currentPage;
      if (this.name_filter != "") {
        url = url + "&name=" + this.name_filter;
      }
      const options = {
        method: "GET",
        // headers: {Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`, "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          this.userTable = res.data.results;
          this.rows = res.data.results.length;
          this.total = Math.ceil(res.data.count / this.perPage);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoEditUser(id) {
      this.$router.push({ name: "eca-editeusers", params: { id: id } });
    },

    deleteUser(userid) {
      const data = {
        id: userid,
      };
      const options = {
        method: "DELETE",
        data: data,
        // headers: {Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`, "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `user/users/${userid}/`,
      };
      this.$http(options)
        .then((res) => {
          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #7367f0 !important;
  color: #fff !important;
}
[dir] .table th,
[dir] .table td {
  padding: 0.72rem 2rem;
  text-align: center;
}
</style>
